// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { INTENT_TYPE } from 'common/constants';
import { TASK_STATUS } from 'common/api/resources/Task';

export const TASK_STATUS_INTENT_MAP = {
    [TASK_STATUS.PENDING]: INTENT_TYPE.WARNING,
    [TASK_STATUS.QUEUED]: INTENT_TYPE.WARNING,
    [TASK_STATUS.CANCELED]: INTENT_TYPE.DANGER,
    [TASK_STATUS.FAILED]: INTENT_TYPE.DANGER,
    [TASK_STATUS.DONE]: INTENT_TYPE.SUCCESS,
    [TASK_STATUS.DONE_WITH_ERRORS]: INTENT_TYPE.DANGER,
};

