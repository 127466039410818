// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { RouteComponentProps } from 'react-router';
import {
    Action,
    Toolbar,
    ToolbarGroup,
    Translate,
} from '@plesk/ui-library';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import * as vpcNetworkActions from 'common/modules/vpcNetwork/actions';
import { IpBlockListType } from 'common/api/resources/IpBlock';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import List from 'common/components/List/List';
import { dataCySelector } from 'common/tests/selectors';
import { Dialog } from 'common/components/Dialog/Dialog';
import VpcNetworkAddIpForm from 'admin/vpcNetwork/containers/VpcNetworkAddIpForm';
import { StyledActions } from 'common/components/Actions/Styles';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { IVpcNetworkIpResponse } from 'common/api/resources/VpcNetwork';
import { TABLE_ACTIONS } from 'common/modules/vpcNetwork/constants/tests';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';

export type VpcNetworkIpsProps =
    RouteComponentProps<{ id: string }> &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum VpcNetworkIpsTableColumns {
    ID = 'colId',
    IP = 'colIp',
    SERVER = 'colServer',
    ACTIONS = 'colActions',
}

const columns = [
    {
        width: '1%',
        key: VpcNetworkIpsTableColumns.ID,
        title: <Translate content="vpcNetwork.ip.list.id" />,
    },
    {
        key: VpcNetworkIpsTableColumns.IP,
        title: <Translate content="vpcNetwork.ip.list.ip" />,
        cellProps: {
            className: 'cell-bold',
        },
    },
    {
        key: VpcNetworkIpsTableColumns.SERVER,
        title: <Translate content="vpcNetwork.ip.list.server" />,
    },
    getActionColumnProps(),
];

const canBeRemoved = (item: IVpcNetworkIpResponse) => !item.server?.id;

export const VpcNetworkIps: React.FC<VpcNetworkIpsProps> = ({
    vpcNetwork: {
        ipList,
        item,
    },
    match: { params },
    loadingFlags: {
        isLoading,
        isLoadingList,
    },
    vpcNetworkActions: {
        getVpcNetwork,
        getVpcNetworkIps,
        removeIpFromVpcNetwork,
        removeIpsFromVpcNetwork,
    },
}) => {
    const vpcNetworkId = parseInt(params.id, 10);
    React.useEffect(() => {
        getVpcNetwork(vpcNetworkId);
        getVpcNetworkIps(vpcNetworkId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadPaginated = (page: number) => getVpcNetworkIps(vpcNetworkId, { page });
    const isFirstLoading = useIsFirstLoading(isLoading);

    const [isDialogOpen, setDialogOpen] = React.useState(false);

    const [selection, setSelection] = React.useState<string[]>([]);

    const remove = async (ids: number | number[]) => {
        Array.isArray(ids) ? await removeIpsFromVpcNetwork(item.id, ids) : await removeIpFromVpcNetwork(item.id, ids);
        setSelection([]);

        reloadListData(ipList, loadPaginated);
    };
    const handleItemRemove = (ip: IVpcNetworkIpResponse) => () => remove(ip.id);
    const handleRemoveSelected = () => remove(selection.map((str: string): number => parseInt(str, 10)));

    const data = ipList.data.map((vpcNetworkIp) => ({
        [VpcNetworkIpsTableColumns.ID]: vpcNetworkIp.id,
        [VpcNetworkIpsTableColumns.IP]: vpcNetworkIp.ip,
        [VpcNetworkIpsTableColumns.SERVER]: vpcNetworkIp.server && (
            <Action
                component={Link}
                to={pathTo(`servers/${vpcNetworkIp.server.id}`)}
            >
                {vpcNetworkIp.server.name}
            </Action>
        ),
        [VpcNetworkIpsTableColumns.ACTIONS]: (
            <StyledActions>
                <ButtonWithConfirmation
                    disabled={!canBeRemoved(vpcNetworkIp)}
                    isLoading={isLoading}
                    translations={{
                        title: <Translate content="vpcNetwork.ip.deleteConfirmation.title" />,
                        button: <Translate content="vpcNetwork.ip.deleteConfirmation.button" />,
                        tooltip: <Translate content="vpcNetwork.ip.deleteConfirmation.tooltip" />,
                        text: <Translate content="vpcNetwork.ip.deleteConfirmation.text" />,
                    }}
                    handleConfirm={handleItemRemove(vpcNetworkIp)}
                    data-cy={dataCySelector(vpcNetworkIp.id, 'remove')}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ),
        key: vpcNetworkIp.id.toString(),
    }));

    return (
        <>
            <PageHeader
                title={<Translate
                    content="vpcNetwork.ip.title"
                    params={{ vpcNetwork: item.name }}
                />}
                buttonText={'vpcNetwork.ip.addBtn'}
                buttonIcon="ip-addresses"
                onButtonClick={() => setDialogOpen(true)}
                isButtonShown={item.list_type === IpBlockListType.SET}
            />
            <Loader isLoading={isFirstLoading}>
                <List
                    emptyView={
                        item.list_type === IpBlockListType.SET ? (
                            <EmptyView
                                title="vpcNetwork.ip.emptyView.set.title"
                                description="vpcNetwork.ip.emptyView.set.description"
                                buttonText="vpcNetwork.ip.emptyView.set.buttonText"
                                onButtonClick={() => setDialogOpen(true)}
                                icon={ICONS.PLUS}
                            />
                        ) : (
                            <EmptyView
                                title="vpcNetwork.ip.emptyView.range.title"
                                description="vpcNetwork.ip.emptyView.range.description"
                                icon={ICONS.NET}
                            />
                        )
                    }
                    toolbar={(
                        <Toolbar>
                            <ToolbarGroup title="actions">
                                <ButtonWithConfirmation
                                    data-cy={TABLE_ACTIONS.IPS_BATCH_REMOVE}
                                    disabled={!selection.length}
                                    isLoading={false}
                                    confirmationButtonGhost={false}
                                    confirmationButtonText={<Translate content="vpcNetwork.ip.batchDeleteConfirmation.button" />}
                                    translations={{
                                        title: <Translate content="vpcNetwork.ip.batchDeleteConfirmation.title" />,
                                        button: <Translate content="vpcNetwork.ip.batchDeleteConfirmation.button" />,
                                        tooltip: <Translate content="vpcNetwork.ip.batchDeleteConfirmation.tooltip" />,
                                        text: <Translate content="vpcNetwork.ip.batchDeleteConfirmation.text" />,
                                    }}
                                    handleConfirm={handleRemoveSelected}
                                    icon={ICONS.RECYCLE}
                                />
                            </ToolbarGroup>
                        </Toolbar>
                    )}
                    columns={columns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={ipList.meta}
                    isLoading={isLoadingList}
                    isFirstLoading={isFirstLoading}
                    selection={selection}
                    onSelectionChange={(items: string[]) => setSelection(items)}
                />
            </Loader>
            <Dialog
                heading={<Translate content="vpcNetwork.ip.form.title.create" />}
                closeHandler={() => setDialogOpen(false)}
                isOpen={isDialogOpen}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoading} center={false}>
                    <VpcNetworkAddIpForm
                        id={item.id}
                        onSubmit={() => {setDialogOpen(false);}}
                    />
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    vpcNetwork: state.vpcNetwork,
    loadingFlags: {
        isLoading: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_ITEM) || state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_IP_LIST),
        isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_IP_LIST),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    vpcNetworkActions: bindActionCreators(vpcNetworkActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VpcNetworkIps);
