// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    ipRule,
    requiredRule,
    validate,
} from 'common/validator';
import * as vpcNetworkActions from 'common/modules/vpcNetwork/actions';
import * as locationActions from 'common/modules/location/actions';
import * as userActions from 'admin/user/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Column,
    Columns,
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    IVpcNetworkRequest,
    IVpcNetworkResponse,
} from 'common/api/resources/VpcNetwork/model';
import { IFormProps } from 'common/components/Form/types';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import {
    IpBlockListType,
    IpBlockListTypeTranslations,
} from 'common/api/resources/IpBlock';
import { IpDash } from 'admin/ipBlock/containers/Styles';
import { ISelectOption } from 'common/components';
import { IShortLocationResponse } from 'common/api/resources/Location';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { createOptionsLoader } from 'common/components/Select/helpers';
import {
    IShortUserResponse,
    IUserResponse,
} from 'common/api/resources/User';

interface IVpcNetworkFormProps {
    onSubmit: () => void;
}

export type VpcNetworkFormProps =
    IVpcNetworkFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const vpcNetworkToRequest = ({ user, location, ...vpcNetwork }: IVpcNetworkResponse): IVpcNetworkRequest => ({
    ...vpcNetwork,
    location_id: location.id,
    user_id: user?.id,
});

const ipBlockListTypeOptions = Object.values(IpBlockListType).map((type) => ({
    value: type.toString(),
    title: <Translate content={IpBlockListTypeTranslations[type.toString()]}/>,
}));

interface ILocationSelectOption extends ISelectOption {
    value: number;
    label: string;
}
const locationToSelectOption = (tag: IShortLocationResponse): ILocationSelectOption => ({
    label: tag.name,
    value: tag.id,
});

const userToSelectOptions = (user: IShortUserResponse): ISelectOption => ({
    label: user.email,
    value: user.id.toString(),
});

export const VpcNetworkForm: React.FC<VpcNetworkFormProps> = ({
    item,
    isItemSaving,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    vpcNetworkActions: {
        unsetVpcNetworkItem,
        createVpcNetwork,
        updateVpcNetwork,
    },
    locationActions: {
        getLocations,
    },
    userActions: {
        getUsers,
    },
    onSubmit,
}) => {
    const [submitValues, setSubmitValues] = React.useState<IVpcNetworkRequest>(
        vpcNetworkToRequest(item)
    );
    const isNewItem = !item.id;

    React.useEffect(() => () => {
        clearFormErrors();
        unsetVpcNetworkItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSubmit = async (values: IVpcNetworkRequest) => {
        const rules = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            netmask: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badNetmask" />, 4),
            ],
        };

        if (values.list_type === IpBlockListType.RANGE) {
            rules['from'] = [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badIpAddress" />, 4),
            ];
            rules['to'] = [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badIpAddress" />, 4),
            ];
        }

        const errors = validate<IVpcNetworkRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        item.id
            ? await updateVpcNetwork(item.id, values)
            : await createVpcNetwork(values);
        onSubmit();
    };

    const [selectedLocation, setSelectedLocation] = React.useState(
        item.location.id ? locationToSelectOption(item.location) : undefined
    );
    const locationsLoader = createOptionsLoader(
        getLocations,
        locationToSelectOption
    );
    const handleLocationChange = (option: ILocationSelectOption) => {
        setSelectedLocation(option);
        setSubmitValues((prevValues) => ({
            ...prevValues,
            location_id: option.value,
        }));
    };

    const [selectedUser, setSelectedUser] = React.useState(
        item.user?.id ? userToSelectOptions(item.user) : undefined
    );
    const usersLoader = createOptionsLoader<IUserResponse>(
        getUsers,
        userToSelectOptions
    );
    const handleUserChange = (option: ISelectOption) => {
        setSelectedUser(option);
        setSubmitValues((prevValues) => ({
            ...prevValues,
            user_id: option.value
                ? typeof option.value === 'string'
                    ? parseInt(option.value, 10)
                    : option.value
                : undefined,
        }));
    };

    return (
        <>
            <Form
                id="vpcNetworkForm"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={handleFieldChange}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="name"
                        size={SIZE.FILL}
                        label={<Translate content="vpcNetwork.form.name" />}
                        required={true}
                    />
                    {isNewItem && (
                        <FormField
                            name="list_type"
                            required={true}
                            label={<Translate content="vpcNetwork.form.listType" />}
                        >
                            {({ setValue }: IFormProps<string>) => (
                                <SegmentedControl
                                    buttons={ipBlockListTypeOptions}
                                    selected={submitValues.list_type}
                                    onChange={setValue}
                                />
                            )}
                        </FormField>
                    )}
                    {(isNewItem || submitValues.list_type === IpBlockListType.RANGE) && (
                        <Columns vertical={false}>
                            <Column>
                                <FormFieldText
                                    name="from"
                                    label={<Translate content="vpcNetwork.form.from" />}
                                    required={true}
                                    disabled={submitValues.list_type === IpBlockListType.SET}
                                    vertical
                                />
                            </Column>
                            <Column width={24}>
                                <IpDash>&mdash;</IpDash>
                            </Column>
                            <Column>
                                <FormFieldText
                                    name="to"
                                    label={<Translate content="vpcNetwork.form.to" />}
                                    required={true}
                                    disabled={submitValues.list_type === IpBlockListType.SET}
                                    vertical
                                />
                            </Column>
                        </Columns>
                    )}
                    <FormFieldText
                        name="netmask"
                        placeholder="255.255.0.0"
                        label={<Translate content="vpcNetwork.form.netmask" />}
                        required={true}
                        size={SIZE.FILL}
                    />
                    <FormField
                        name="location_id"
                        label={<Translate content="vpcNetwork.form.location" />}
                        required={true}
                    >
                        {({ getId }: IFormProps<number>) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                value={selectedLocation}
                                loadOptions={locationsLoader}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                onChange={handleLocationChange}
                                isClearable={true}
                                menuPosition={'fixed'}
                            />
                        )}
                    </FormField>
                    <FormField
                        name="user_id"
                        label={<Translate content="vpcNetwork.form.user" />}
                    >
                        {({ getId }: IFormProps<number>) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                value={selectedUser}
                                loadOptions={usersLoader}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                onChange={handleUserChange}
                                isClearable={true}
                                menuPosition={'fixed'}
                            />
                        )}
                    </FormField>
                </Section>
            </Form>
            <Button
                type="submit"
                form="vpcNetworkForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="vpcNetwork.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.vpcNetwork.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_ITEM_SAVE),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    locationActions: bindActionCreators(locationActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    vpcNetworkActions: bindActionCreators(vpcNetworkActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VpcNetworkForm);
