// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { isProduction } from 'common/helpers/core';
import { compose } from 'redux';
import { AppState } from 'common/modules/app/reducer';
import { LanguageState } from 'common/modules/language/reducer';
import { AuthState } from 'common/modules/auth/reducer';
import { TwoFactorAuthState } from 'common/modules/auth/twoFactorAuth/reducer';
import { PlanState } from 'common/modules/plan/reducer';
import { SettingsState } from 'common/modules/settings/reducer';
import { ConfirmIdentityState } from 'common/modules/auth/confirmIdentity/reducer';
import { OfferState } from 'common/modules/offer/reducer';

// eslint-disable-next-line
declare const window: Window & { devToolsExtension: any, __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any, io: any };

/* eslint-disable no-underscore-dangle */
export const composeEnhancers = !isProduction() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 10,
    })
    : compose;
/* eslint-enable no-underscore-dangle */

export interface ICommonState {
    app: AppState;
    auth: AuthState;
    twoFactorAuth: TwoFactorAuthState;
    confirmIdentity: ConfirmIdentityState;
    language: LanguageState;
    plan: PlanState;
    settings: SettingsState;
    offer: OfferState;
}
