// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    applyMiddleware,
    CombinedState,
    combineReducers,
    createStore,
} from 'redux';
import { StateType } from 'typesafe-actions';
import authReducer from 'common/modules/auth/reducer';
import twoFactorAuthReducer from 'common/modules/auth/twoFactorAuth/reducer';
import projectReducer,
{ ProjectState } from 'client/project/reducers';
import planReducer from 'common/modules/plan/reducer';
import locationReducer,
{ LocationState } from 'common/modules/location/reducer';
import apiTokenReducer,
{ ApiTokenState } from 'common/modules/apiToken/reducer';
import sshKeyReducer,
{ SshKeyState } from 'client/account/reducers/sshKeys';
import osImageReducer,
{ OsImageState } from 'common/modules/osImage/reducer';
import applicationReducer,
{ ApplicationState } from 'common/modules/application/reducer';
import {
    connectRouter,
    routerMiddleware,
} from 'connected-react-router';
import settingsReducer,
{ SettingsState } from 'common/modules/settings/reducer';
import backupReducer,
{ BackupState } from 'common/modules/backup/reducers';
import snapshotReducer  from 'common/modules/snapshot/reducers';
import { createBrowserHistory } from 'history';
import appReducer from 'common/modules/app/reducer';
import {
    createTransform,
    persistReducer,
    persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import {
    composeEnhancers,
    ICommonState,
} from 'common/store';
import languageReducer from 'common/modules/language/reducer';
import graphReducer from 'common/modules/graph/reducers';
import { languageUpdateMiddleware } from 'common/redux/middleware/languageUpdateMiddleware';
import {
    AUTH_PERSIST_KEY,
    STORE_PERSIST_KEY,
} from 'common/redux/constants';
import { tokenMiddleware } from 'common/modules/auth/middlewares/tokenMiddleware';
import { rootReducerDecorator } from 'common/modules/root/reducer';
import { FEATURES } from 'common/modules/app/features/constants';
import { PersistConfig } from 'redux-persist/es/types';
import isoImageReducer,
{ IsoImageState } from 'common/modules/isoImage/reducer';
import offerReducer,
{ OfferState } from 'common/modules/offer/reducer';
import confirmIdentityReducer from 'common/modules/auth/confirmIdentity/reducer';

export interface IAppState extends ICommonState {
    application: ApplicationState;
    apiTokens: ApiTokenState;
    sshKeys: SshKeyState;
    project: ProjectState;
    osImage: OsImageState;
    location: LocationState;
    settings: SettingsState;
    backup: BackupState;
    isoImage: IsoImageState;
    offer: OfferState;
}

const rootPersistConfig = {
    key: AUTH_PERSIST_KEY,
    storage,
    whitelist: ['auth'],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appPersistConfig: PersistConfig<CombinedState<any>> = {
    key: STORE_PERSIST_KEY,
    storage,
    transforms: [
        createTransform<Set<FEATURES>, FEATURES[]>(
            (inboundState) => Array.from(inboundState.values()),
            (outboundState) => new Set(outboundState),
            { whitelist: ['features'] }
        ),
    ],
    whitelist: ['viewType', 'language', 'features', 'lastUsedSshKeys'],
};

const settingsPersistConfig = {
    key: 'settings',
    storage,
    whitelist: ['theme', 'features'],
};

export const history = createBrowserHistory();
export const rootReducer = combineReducers({
    app: persistReducer(appPersistConfig, appReducer),
    application: applicationReducer,
    auth: authReducer,
    twoFactorAuth: twoFactorAuthReducer,
    confirmIdentity: confirmIdentityReducer,
    apiTokens: apiTokenReducer,
    sshKeys: sshKeyReducer,
    project: projectReducer,
    plan: planReducer,
    location: locationReducer,
    osImage: osImageReducer,
    settings: persistReducer(settingsPersistConfig, settingsReducer),
    router: connectRouter(history),
    backup: backupReducer,
    snapshots: snapshotReducer,
    language: languageReducer,
    graph: graphReducer,
    isoImage: isoImageReducer,
    offer: offerReducer,
});

export const store = createStore(
    persistReducer(rootPersistConfig, rootReducerDecorator(rootReducer)),
    composeEnhancers(
        applyMiddleware(thunk, routerMiddleware(history), tokenMiddleware, languageUpdateMiddleware)
    )
);

export const persistor = persistStore(store);

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
